<template>
  <div data-vue-component-name="Home">
    <HomeList :pagesInfo="pages" />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { excludeKeys } from '@xsys2/functions';

const store = useStore();
const pagesList = computed(() => store.state.common.menuItems);

const pages = excludeKeys(pagesList.value, ['homepage']);
</script>

<style scoped lang="scss">
[data-vue-component-name="Home"] {
  width: 100vw;
  height: calc(100vh - 56px);
}
</style>
